export enum FeatureToggle {
  CREATE_PO_PRODUCTS = 'create-po-products',
  VIEW_LPS = 'view-lps',
  VIEW_BRANDS = 'view-brands',
  PARKED_SALE_ENHANCEMENTS = 'parked-sale-enhancements',
  NEW_PRODUCT_SEARCH = 'new-product-search',
  SMS_MARKETING = 'sms-marketing',
  CAN_USE_WORLDPAY = 'can-use-worldpay',
  CANNABINOID_PER_BATCH = 'cannabinoid-per-batch',
  CAN_VIEW_SHIFTV2 = 'can-view-shiftsv2',
  CAN_VIEW_ECOMMERCE_SETTINGS = 'can-view-ecommerce-settings',
  CAN_VIEW_DIGITAL_SIGNAGE = 'can-view-digital-signage',
  CAN_USE_WEBSOCKET = 'websocket',
  PRODUCT_DETAILS_PACKAGE_DATE = 'product-details-package-date',
  PRODUCT_DETAILS_TERPENES_AND_CANNABINOIDS = 'product-details-terpenes-cannabinoids',
  ONLINE_ORDERING_MODULE = 'online-ordering-module',
  IRCC = 'IRCC',
  CAN_USE_LOYALTY_POINTS = 'can-use-loyalty-points',
  MB_QUARTERLY_COMPLIANCE_REPORT = 'mb-quarterly-compliance-report',
  LOYALTY_AS_PAYMENT_TYPE = 'loyalty-as-payment-type',
  CAN_USE_OCS_COMPLIANCE = 'ocs-compliance',
  LOYALTY_POINTS_FOR_DISCOUNTS = 'loyalty-points-for-discounts',
}
