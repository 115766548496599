import * as React from 'react'
import { observer, Provider } from 'mobx-react'
import { LoadingAnimation } from '../components/LoadingAnimation'
import { CurrentCompanyStore } from '../stores/CurrentCompanyStore'
import { CurrentUserStore } from '../stores/CurrentUserStore'
import * as auth from '../utilities/auth'

interface Props {
  params: any
}

@observer
export class CompanyRoot extends React.Component<Props, {}> {
  currentUserStore = new CurrentUserStore()
  currentCompanyStore = new CurrentCompanyStore()

  componentDidMount = async () => {
    const authToken = window.localStorage.getItem('authToken')

    if (authToken !== null) {
      try {
        await auth.setupInterceptor()
        await this.currentUserStore.getCurrentHumanUser()
      } catch (error) {
        window.location.href = '/'
      }
    }

    try {
      await this.currentCompanyStore.getById(this.props.params.companyId)
      await this.currentCompanyStore.getLocations()

      if (!this.currentCompanyStore.globalLocations) {
        await this.currentCompanyStore.getGlobalLocations()
      }
      await this.currentCompanyStore.getQueueEnhancementsFeature()
      await this.currentCompanyStore.getSMSMarketingStatus()
      await this.currentCompanyStore.getWorldpayStatus()
      await this.currentCompanyStore.getCannabinoidPerBatchStatus()
      await this.currentCompanyStore.getViewShiftV2Status()
      await this.currentCompanyStore.getProductDetailsPackageDateStatus()
      await this.currentCompanyStore.getProductDetailsTerpenesAndCannbinoidsStatus()
      await this.currentCompanyStore.getCanUseLoyaltyPoints()
      await this.currentCompanyStore.getLoyaltyAsPaymentType()
      await this.currentCompanyStore.getCanUseOCSComplianceStatus()
      await this.currentCompanyStore.getLoyaltyPointsForDiscount()

      if (this?.currentCompanyStore?.canUseOCSCompliance) {
        await this.currentCompanyStore.getGlobalHashKeys()
      }

      await auth.setupInterceptor()
    } catch (error) {
      if (error && error.response && [400, 401, 403, 404].includes(error.response.status)) {
        window.location.href = '/'
      }
    }
  }

  render() {
    const companyLoaded = this.currentCompanyStore.company !== undefined
    const userLoaded = this.currentUserStore.currentUser !== undefined
    const locationsLoaded = this.currentCompanyStore.locations !== undefined

    if (!userLoaded || !companyLoaded || !locationsLoaded) {
      return (
        <div className='full-height'>
          <div className='container'>
            <LoadingAnimation />
          </div>
        </div>
      )
    } else {
      return (
        <Provider
          currentUserStore={this.currentUserStore}
          currentCompanyStore={this.currentCompanyStore}
        >
          <div>{this.props.children}</div>
        </Provider>
      )
    }
  }
}
